import Vue from "vue";
import Vuex from "vuex";
import common from "./modules/common";
import interview from "./modules/interview";
import dashboard from "./modules/dashboard";

Vue.use(Vuex);

export default new Vuex.Store({
    state () {
        return {
            slideDisplay: "hide"
        }
    },
    mutations : {
        showSlide(state) {
            state.slideDisplay = "show";
        },
        hideSlide(state) {
            state.slideDisplay = "hide";
        },
    },
    getters: {
        slideDisplayStatus(state) {
            return state.slideDisplay;
        }
    },
    actions : {},
    modules : {
        interview: interview,
        common: common,
        dashboard: dashboard
    },
});
