<template>
  <div id="app">
    <v-app>
      <component :is="layout">
        <router-view />
      </component>
      <loading :active="getIsLoading" is-full-page />
    </v-app>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapGetters, mapMutations } from "vuex"
import { getInfo } from "@/common/localStorage";

export default {
    name : "App",
    components: {
        Loading
    },
    computed : {
        layout() {
            return (this.$route.meta.layout || "default") + "-layout";
        },
        ...mapGetters("common", ["getIsLoading"])
    },
    created() {
        const userLogin = getInfo()
        if (userLogin) {
            try {
                const userLoginObj = JSON.parse(userLogin)
                this.setUser(userLoginObj)
            } catch (e) {
                this.setUser(undefined)
            }
        }
    },
    watch: {
        $route(){
            $(".container-fluid").css("height", "auto");
            this.hideSlide()
        }
    },
    methods: {
        ...mapMutations({
            setUser: "common/setUser",
            hideSlide: "hideSlide"
        })
    }
};
</script>
<style lang="sass">
  .v-application
    height: 100vh
    .v-application--wrap
      min-height: unset

  .justify-center
    & .v-application--wrap
      justify-content: center
  .nav-bar-group
    .v-list-group__header
      .v-list-item__icon, .v-ripple__container
        display: none
    .v-list-item
      min-height: auto
      padding: 0
    .v-list-group__items
      .v-list-item__content
        padding: 0
    .position-absolute
      right: 9px
      bottom: 8px
    & .nav-bar-rotate
      transform: rotate(90deg)
      transition: transform 0.3s linear
    & .nav-bar-rollback
      transform: rotate(0deg)
      transition: transform 0.3s linear

  .v-list-group__items .v-list-item
    overflow: initial !important

  .demo-list
    display: flex
    flex-direction: column
    height: 100%
    .data-table-container
      display: flex
  .table>:not(caption)>*>*
    border-bottom-width: 0 !important
  .navigation
    max-width: 80% !important
    .v-navigation-drawer__content
      overflow-x: auto
  .v-menu__content
    z-index: 12 !important
  .v-menu__content.v-autocomplete__content
    z-index: 999 !important
</style>
