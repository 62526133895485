// 製品名
export const PRODUCT_NAME = "特定保健指導支援システム";

// 環境変数
export const ENVS = {
    // -------------------------------------------------
    // 必須
    // -------------------------------------------------
    // API
    API_URL_BASE: process.env.VUE_APP_API_URL_BASE,

    // (マルチテナント用) テナント名
    TENANT_NAME: process.env.VUE_APP_TENANT_NAME,
    // (マルチテナント用) 利用開始日
    TENANT_START_DATE: process.env.VUE_APP_TENANT_START_DATE || "2000-01-01",

    // -------------------------------------------------
    // 任意　(指定しない場合デフォルト値0
    // -------------------------------------------------

    // 言語
    I18N_LOCALE: process.env.VUE_APP_I18N_LOCALE || "ja",
    I18N_FALLBACK_LOCALE: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "ja",
    //
    SUPPORT_PROG_SCOND_DEFAULT_PERIOD_OVER: Number(process.env.VUE_APP_SUPPORT_PROG_SCOND_DEFAULT_PERIOD_OVER || "3"),
    //
    SUPPORT_PROG_WARN_REQED_SUPPORT_UNASSIGNED: Number(process.env.VUE_APP_SUPPORT_PROG_WARN_REQED_SUPPORT_UNASSIGNED || "7"),
    //
    DEFAULT_TAX_RATE: Number(process.env.VUE_APP_DEFAULT_TAX_RATE | "10"),
    //
    DEFAULT_TAX_RATE_FRACTIONATING_DIV: Number(process.env.VUE_APP_DEFAULT_TAX_RATE_FRACTIONATING_DIV || "2"),
    // 警告を出す健診時の体重との差異
    WARN_INPUT_DIFF_WEIGHT: Number(process.env.VUE_APP_WARN_REQED_INPUT_DIFF_WEIGTH || "5"),
    // 警告を出す健診時の腹囲との差異
    WARN_INPUT_DIFF_WAIST: Number(process.env.VUE_APP_WARN_REQED_INPUT_DIFF_WAIST || "5"),
    //
    SUPPORT_PROG_WARN_REQED_PERIOD_OVER: Number(process.env.VUE_APP_SUPPORT_PROG_WARN_REQED_PERIOD_OVER || "1"),
    //
    SUPPORT_PROG_SCOND_DEFAULT_SUPPORT_UNASSIGNED: Number(process.env.VUE_APP_SUPPORT_PROG_SCOND_DEFAULT_SUPPORT_UNASSIGNED || "7"),
    //
    DEFAULT_REQED_SUPPORT_POINT: Number(process.env.VUE_APP_DEFAULT_REQED_SUPPORT_POINT || "180"),
    //
    EXTERNAL_LINK_BULLETIN_BOAR: process.env.VUE_APP_EXTERNAL_LINK_BULLETIN_BOARD ?? "",
    // EeasyライブラリURL
    EEASY_LIB_URL: process.env.VUE_APP_EEASY_LIB_URL || "https://js.eeasy.jp/v1",
    // Eeasyライブラリをロードする場合は1、ロードしない場合はブランク
    EEASY_LIB_LOAD_ENABLE: Number(process.env.VUE_APP_EEASY_LIB_LOAD_ENABLE || "1"),
}

export const MESSAGE = {
    TOKEN_EXPIRED : "Token expired",
    LOGIN_SUCCESS : "Login success",
    INVALID_ACCOUNT : "Invalid Dusername or password",
    DISABLE_USER_FLAG_FALSE: 0,
    DISABLE_USER_FLAG_TRUE: 1,
};

export const ROLE = {
    SYST_ADMIN: 1,
    SUPPORTER: 2,
    BACK_OFFICE: 4,
    SYST_MAINTAIN: 8,
};

export const MENU = [
    {
        name : "dashboard",
        title : "Dashboard",
        icon : "fab fa-windows",
    },
    {
        name : "product",
        title : "Product",
        icon : "fab fa-accusoft",
    },
    {
        name : "store",
        title : "Store",
        icon : "fas fa-store-alt",
    },
    {
        name : "cart",
        title : "Cart",
        icon : "fas fa-shopping-cart",
    },
    {
        name : "employee",
        title : "Employee",
        icon : "fas fa-user",
    },
];

export const CATEGORY_CODE_USER = "00001700";

export const ROLE_CODE_USER = "00001710";

export const STATUS_SUCCESS_CODE = 200;

export const GROUP_USER_OCCUPATION = "00001700";
export const GROUP_USER_ROLE = "00001710";

export const MAX_LOGIN_FAIL = 5;

export const ROLE_HIDE = 1;

export const CODE_IMPORT = "00000520";
export const RESULT_IMPORT = "00000510";
export const CODE = "00000700";
export const CODE_SUPPORT_STATUS = "00001400";
export const CODE_STATUS_SUPPORT = "00000610";
export const CODE_RECEPTION = "00001300";
export const CONTACT_FORM = "00000200";
export const RESULT = "00001100";
export const REASON = "00001110";
export const CODE_MEDICAL_INDICATION = "00000530";
export const CODE_FAMILY = "00000400";
export const CODE_FRACTION = "00000900";
export const CODE_SUPPORT_FLOW_STATUS = "00001400";
export const CODE_SUPPORT_EXEC_DIV = "00000600";
export const CODE_SUPPORT_PROGRESS_STATUS = "00001430";
export const CODE_HEALTH_GUIDANCE_CONTRACT_DIV = "00000300";
export const CODE_HEALTH_GUIDANCE_DIV = "00000700";
export const CODE_REASON_TERMINATE = "00001410";
export const CODE_REASON_FIRST_DECLINE = "00001420";

export const STEP_STATUS_ONE = 1;
export const STEP_STATUS_TWO = 2;
export const STEP_STATUS_THREE = 3;

export const CODE_CHANGE_ACTIVITY = "0001000050";
export const CODE_SMOCKING = "0001000100";
export const CODE_ALCOHOL = "0001000110";
export const CODE_RESULT = "0001000120";
export const CODE_WISH_CONTINUE_SUPPORT = "0001000240";
export const CODE_CONTINUE_SUPPORT = "0001000251";
export const CODE_SEX = "00000100";


export const CODE_EATING_PLAN = "0002000080";
export const CODE_ACTIVITY_PLAN = "0002000090";
export const CODE_SMOCKING_PLAN = "0002000100";

export const CODE_GENDER = "00000100";
export const CODE_TIME_OF_MAKING_INVOICE = "00001600";

export const CODE_XML_OUTPUT_STATUS = "00001870";

export const OUT_OF_SUPPORT_PATIENT_REF_LEVEL = "00001850";

export const CODE_CLIENT = "00000800";
export const CODE_SMS_HANA_ERROR = "00001910";
export const CODE_BLAST_ENGINE_ERROR = "00001920";

export const CODE_SCHEDULING_DIV = "00002000";
export const CODE_SCHEDULING_PROG_STATUS = "00002010";
export const CODE_SCHEDULING_RESULT_STATUS = "00002020";
export const CODE_SCHEDULING_RESULT_STATUS_DETAIL = "00002030";
export const CODE_EEASY_WEBHOOK_TYPE = "00002040";
export const CODE_CONTACT_MEAN = "00002300";
export const CODE_SEND_METHOD = "00002310";
export const CODE_SEND_EXEC_STATUS = "00002320";

// TODO: Button confirm, save draft, edit, delete segment
export const BTN_CONFIRM = "confirm";
export const BTN_SAVE_DRAFT = "draft";
export const BTN_EDIT = "edit";
export const BTN_DELETE = "delete";
export const BTN_CLEAR = "clear";

// support_progress_status_code
export const SUPPORT_PROGRESS_PLAN = 10;
export const SUPPORT_PROGRESS_SCHEDULE = 20;
export const SUPPORT_PROGRESS_IN_PROGRESS = 30;
export const SUPPORT_PROGRESS_CONFIRM = 40;
export const SUPPORT_PROGRESS_CANCEL = 50;

// health guidance reception status
export const HEALTH_GUIDANCE_RECEPTION_FALSE = 10;
export const HEALTH_GUIDANCE_RECEPTION_TRUE = 20;

// list case check validate
export const In_Required = "In_Required"
export const Sel_Required = "Sel_Required"
export const In_MaxLen = "In_MaxLen"

export const KeyWeight = "weight"
export const KeyWaist = "waistCircumference"

export const vue2EditorCustomize = [
    ["bold", "italic", "underline", "strike"],
    [{ "header": [1, 2, 3, 4, 5, 6, false] }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ "color": [] }, { "background": [] }],
    [{ "align": [] }],
];

export const TOAST_TIME_OUT = 2500;


export const SYS_SETTING_KEY_EEASY_SYSTEM_PUBLIC_KEY = "EEASY_SYSTEM_PUBLIC_KEY";
export const SYS_SETTING_KEY_EEASY_RECEPTION_PAGE_USER_EMAIL = "EEASY_RECEPTION_PAGE_USER_EMAIL";
export const SYS_SETTING_KEY_EEASY_RECEPTION_PAGE_QUERY = "EEASY_RECEPTION_PAGE_QUERY";
export const SYS_SETTING_KEY_EEASY_LIB_URL = "EEASY_LIB_URL";


export const HEALTH_GUIDANCE_DIV_ASSERTIVE = "1"; // 積極的支援
export const HEALTH_GUIDANCE_DIV_MOTIVATION = "2"; // 動機付け支援
export const HEALTH_GUIDANCE_DIV_MOTIVATION_EQ = "3"; // 動機付け支援相当