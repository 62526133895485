import authenticated from "@/middleware/authenticated";
import { ROLE } from "@/common/constants";


export default [
    {
        path : "/",
        beforeEnter : authenticated,
        name : "dashboard",
        component : () =>
            import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard.vue"),
        meta : {
            title : "Dashboard",
            explain : "Dashboard explain",
        },
    },
    {
        path : "/m",
        beforeEnter : authenticated,
        component: {
            render(c) {
                return c("router-view")
            }
        },
        children: [
            {
                path: "",
                name : "demo",
                component : () =>
                    import("@/pages/Mobile/Demo.vue"),
                meta : {
                    layout : "mobile",
                    title : "ユーザ管理",
                },
            },
            {
                path : "dashboard",
                name : "mobile.form-support",
                component : () =>
                    import("@/pages/Mobile/Index.vue"),
                meta : {
                    layout : "mobile",
                    title : "Mobile"
                }
            },
            {
                path: "support-schedule-list",
                beforeEnter : (to, from, next) => authenticated(to, from, next, [ROLE.SUPPORTER, ROLE.SYST_ADMIN]),
                name : "SupportScheduleListMobile",
                component : () =>
                    import("@/pages/Support-Schedule-List/SupportScheduleList.vue"),
                meta : {
                    layout : "mobile",
                    title : "支援予定一覧",
                },
            }
        ]
    },
    {
        path : "/product",
        name : "product",
        beforeEnter : authenticated,
        component : () =>
            import(/* webpackChunkName: "product" */ "@/pages/Product.vue"),
        meta : {
            title : "Product",
            explain : "Product explain",
        },
    },
    {
        path : "/store",
        name : "store",
        beforeEnter : authenticated,
        component : () =>
            import(/* webpackChunkName: "store" */ "@/pages/Store.vue"),
        meta : {
            title : "Store",
            explain : "Store explain",
        },
    },
    {
        path : "/cart",
        name : "cart",
        beforeEnter : authenticated,
        component : () => import(/* webpackChunkName: "cart" */ "@/pages/Cart.vue"),
        meta : {
            title : "Cart",
            explain : "Cart explain",
        },
    },
    {
        path : "/employee",
        name : "employee",
        beforeEnter : authenticated,
        component : () =>
            import(/* webpackChunkName: "employee" */ "@/pages/Employee.vue"),
        meta : {
            title : "Employee",
            explain : "Employee explain",
        },
    },

    // Handle Router Authenticated
    {
        path : "/login",
        name : "login",
        component : () =>
            import(/* webpackChunkName: "login" */ "@/pages/Login.vue"),
        meta : {
            layout : "auth",
            title : "Login",
        },
    },
    {
        path : "/change-password",
        beforeEnter : authenticated,
        name: "change-pass",
        component: () =>
            import("@/pages/change-password/changePassword.vue"),
        meta : {
            layout : "default",
            title : "パスワード変更",
        }
    },
    {
        path : "/reset-password",
        component: {
            render(c) {
                return c("router-view")
            }
        },
        children: [
            {
                path : "",
                name : "resetPassword",
                component : () =>
                    import("@/pages/reset-password/resetPassword.vue"),
                meta : {
                    layout : "auth",
                    title : "reset-password",
                }
            },
            {
                path : "confirm/:email",
                name : "resetPasswordConfirm",
                component : () =>
                    import("@/pages/reset-password/confirmResetPassword.vue"),
                meta : {
                    layout : "auth",
                    title : "reset-password-confirm",
                }
            }
        ]
    },

    // Handle Router admin manager
    {
        path : "/admin",
        component: {
            render(c) {
                return c("router-view")
            }
        },
        beforeEnter : authenticated,
        children: [
            {
                path : "/",
                name : "admin",
                component : () =>
                    import("@/pages/CommonElement.vue"),
                meta : {
                    layout : "default",
                    title : "Common Element",
                }
            },

            // Handle Router admin system and admin maintenance in side システム管理
            {
                path : "users",
                beforeEnter : (to, from, next) => authenticated(to, from, next, [ROLE.SYST_ADMIN, ROLE.SYST_MAINTAIN]),
                component: {
                    render(c) {
                        return c("router-view")
                    }
                },
                meta : {
                    layout : "default",
                    title : "ユーザ管理",
                },
                children: [
                    {
                        path: "",
                        name : "user-list",
                        component : () =>
                            import("@/pages/User/List.vue"),
                        meta : {
                            layout : "default",
                            title : "ユーザ管理",
                        },
                    }
                ]
            },

            // Handle Router admin system
            {
                path : "list-user",
                name : "list",
                beforeEnter : (to, from, next) => authenticated(to, from, next, [ROLE.SYST_ADMIN]),
                component : () =>
                    import(/* webpackChunkName: "list user" */ "@/pages/Admin/ListUser.vue"),
                meta : {
                    layout : "default",
                    title : "List User",
                },
            },
            {
                path : "add-user",
                name : "add-user",
                beforeEnter : (to, from, next) => authenticated(to, from, next, [ROLE.SYST_ADMIN]),
                component : () =>
                    import("@/pages/Admin/SystemUser/Add"),
                meta : {
                    layout : "default",
                    title : "Add System User",
                }
            },
            {
                path : "edit-user/:id",
                name : "edit-user",
                beforeEnter : (to, from, next) => authenticated(to, from, next, [ROLE.SYST_ADMIN]),
                component : () =>
                    import("@/pages/Admin/SystemUser/Edit"),
                meta : {
                    layout : "default",
                    title : "Edit System User",
                }
            },

            // Handle Router admin system and back office in side 支援業務
            {
                path : "import-diagnostic-data",
                beforeEnter : (to, from, next) => authenticated(to, from, next, [ROLE.BACK_OFFICE, ROLE.SYST_ADMIN]),
                component: {
                    render(c) {
                        return c("router-view")
                    }
                },
                meta : {
                    layout : "default",
                    title : "特定健診データ取込",
                },
                children: [
                    {
                        path: "",
                        name : "import-diagnostic-data-list",
                        component : () =>
                            import("@/pages/ImportDiagnosticData/List.vue"),
                        meta : {
                            layout : "default",
                            title : "特定健診データ取込",
                        },
                    }
                ]
            },
            {
                path : "medical-designated-subjects",
                beforeEnter : (to, from, next) => authenticated(to, from, next, [ROLE.BACK_OFFICE, ROLE.SYST_ADMIN]),
                component: {
                    render(c) {
                        return c("router-view")
                    }
                },
                meta : {
                    layout : "default",
                    title : "保健指導対象者一覧",
                },
                children: [
                    {
                        path: "",
                        name : "medical-designated-subjects-list",
                        component : () =>
                            import("@/pages/medical-designated-subjects/List.vue"),
                        meta : {
                            layout : "default",
                            title : "保健指導対象者一覧",
                        },
                    }
                ]
            },
            {
                path : "medical-designated-reception",
                beforeEnter : (to, from, next) => authenticated(to, from, next, [ROLE.BACK_OFFICE, ROLE.SYST_ADMIN]),
                component: {
                    render(c) {
                        return c("router-view")
                    }
                },
                meta : {
                    layout : "default",
                    title : "保健指導受付",
                },
                children: [
                    {
                        path: "",
                        name : "medical-designated-reception-list",
                        component : () =>
                            import("@/pages/medical-designated-reception/MedicalDesignatedReception.vue"),
                        meta : {
                            layout : "default",
                            title : "保健指導一括受付",
                        },
                    }
                ]
            },

            // Handle Router admin system, back office, supporter in side 支援状況管理
            {
                path: "support-schedule-list",
                beforeEnter : (to, from, next) => authenticated(to, from, next, [ROLE.SUPPORTER, ROLE.BACK_OFFICE, ROLE.SYST_ADMIN]),
                name : "SupportScheduleList",
                // name : "SupportScheduleListMobile",
                component : () =>
                    import("@/pages/Support-Schedule-List/SupportScheduleList.vue"),
                meta : {
                    layout : "default",
                    title : "支援予定一覧",
                },
            },

            {
                path : "list-bill",
                beforeEnter : (to, from, next) => authenticated(to, from, next, [ROLE.BACK_OFFICE, ROLE.SYST_ADMIN]),
                name : "list-bill",
                component : () =>
                    import("@/pages/Dashboard/List.vue"),
                meta : {
                    layout : "default",
                    title : "List",
                },
            },
            {
                path : "test",
                name : "test",
                component : () =>
                    import(/* webpackChunkName: "a" */ "@/pages/CommonElement.vue"),
                meta : {
                    layout : "default",
                    title : "Common Element 2",
                }
            },
            {
                path : "support-pattern",
                name : "support.pattern.index",
                component : () =>
                    import("@/pages/support-pattern/index.vue"),
                meta : {
                    layout : "default",
                    title : "支援パターン管理",
                }
            },
        ]
    },

    // 日程調整 連絡先一覧
    {
        path: "/scheduling/contact",
        beforeEnter : (to, from, next) => authenticated(to, from, next, [ROLE.BACK_OFFICE, ROLE.SYST_ADMIN]),
        component: () => import(/* webpackChunkName: "form-input-client" */ "@/pages/scheduling/contact-list.vue"),
        meta: {
            layout : "default",
            title : "日程調整 事前登録連絡先一覧",
        }
    },

    // 日程調整 連絡先紐付一覧
    {
        path: "/scheduling/patient-contact",
        beforeEnter : (to, from, next) => authenticated(to, from, next, [ROLE.BACK_OFFICE, ROLE.SYST_ADMIN]),
        component: () => import(/* webpackChunkName: "form-input-client" */ "@/pages/scheduling/patient-contact-list.vue"),
        meta: {
            layout : "default",
            title : "日程調整 対象者-連絡先紐付一覧",
        }
    },

    // 日程調整 日程調整一覧
    {
        path: "/scheduling/scheduling",
        beforeEnter : (to, from, next) => authenticated(to, from, next, [ROLE.BACK_OFFICE, ROLE.SYST_ADMIN]),
        component: () => import(/* webpackChunkName: "form-input-client" */ "@/pages/scheduling/scheduling-list.vue"),
        meta: {
            layout : "default",
            title : "日程調整一覧",
        }
    },

    // Handle Router admin system, back office in side 支援状況管理
    {
        path: "/support-progress-management",
        beforeEnter : (to, from, next) => authenticated(to, from, next, [ROLE.BACK_OFFICE, ROLE.SYST_ADMIN]),
        component: () => import(/* webpackChunkName: "form-input-client" */ "@/pages/Support-progress-management/index.vue"),
        meta: {
            layout : "default",
            title : "支援進捗管理",
        }
    },

    {
        path : "/dashboard",
        beforeEnter : (to, from, next) => authenticated(to, from, next, [ROLE.SUPPORTER, ROLE.BACK_OFFICE, ROLE.SYST_ADMIN]),
        component: {
            render(c) {
                return c("router-view")
            }
        },
        children: [
            {
                path : "",
                name : "dashboard.index",
                component : () =>
                    import("@/pages/Dashboard/Index.vue"),
                meta : {
                    layout : "default",
                    title : "Dashboard",
                }
            },
        ]
    },
    {
        path: "/client-request",
        component: () => import(/* webpackChunkName: "form-input-client" */ "@/pages/client/FormInput.vue"),
        meta: {
            layout : "empty",
            title : "回答フォーム",
        }
    },
    {
        path: "/feedback",
        component: () => import(/* webpackChunkName: "form-input-client" */ "@/pages/client/Feedback.vue"),
        meta: {
            layout : "empty",
            title : "返信用フォーム",
        }
    },

    {
        path: "/invoice-data-report",
        name : "invoice-data-report",
        beforeEnter : authenticated,
        component: () => import(/* webpackChunkName: "form-input-client" */ "@/pages/Invoice-data-report/index.vue"),
        meta: {
            layout : "default",
            title : "請求・報告書データ作成",
        }
    },

    {
        path: "/export-invoice",
        name : "export-invoice",
        beforeEnter : authenticated,
        component: () => import(/* webpackChunkName: "form-input-client" */ "@/pages/export-invoice/index.vue"),
        meta: {
            layout : "default",
            title : "請求データ出力",
        }
    },

    {
        path: "/export-data",
        name : "export-data",
        beforeEnter : authenticated,
        component: () => import(/* webpackChunkName: "form-input-client" */ "@/pages/export-data/index.vue"),
        meta: {
            layout : "default",
            title : "データエクスポート",
        }
    },

    {
        path: "/admin/specific-health-guidance-organizations-management",
        name : "specific-health-guidance-organizations-management",
        beforeEnter : authenticated,
        component: () => import(/* webpackChunkName: "form-input-client" */ "@/pages/specific-health-guidance-organizations-management/index.vue"),
        meta: {
            layout : "default",
            title : "特定保健指導機関管理",
        }
    },

    {
        path: "/admin/partner-management",
        name : "partner-management",
        beforeEnter : authenticated,
        component: () => import(/* webpackChunkName: "form-input-client" */ "@/pages/partner-management/index.vue"),
        meta: {
            layout : "default",
            title : "取引先管理",
        }
    },

    {
        path: "/admin/payment-agent-management",
        name : "payment-agent-management",
        beforeEnter : authenticated,
        component: () => import(/* webpackChunkName: "form-input-client" */ "@/pages/payment-agent-management/index.vue"),
        meta: {
            layout : "default",
            title : "支払代行機関管理",
        }
    },

    { path : "*", redirect : "/" },
];
