const state = {
    rejectReviewOrg: {
        support_decline_setting_date: null,
        support_decline_setting_reason: null,
        support_decline_setting_memo: null,
        support_stop_flag: false,
    },
    middleTerminate: {
        support_stop_flag: false,
        support_stop_setting_date: null,
        support_stop_reason: null,
        support_stop_memo: null,
    },
}

const getters = {
    getRejectReviewOrg(state) {
        return state.rejectReviewOrg
    },
    getMiddleTerminate(state) {
        return state.middleTerminate
    },
}

const mutations = {
    setRejectReviewOrg(state, data) {
        state.rejectReviewOrg.support_decline_setting_date = data.support_decline_setting_date
        state.rejectReviewOrg.support_decline_setting_reason = data.support_decline_setting_reason
        state.rejectReviewOrg.support_decline_setting_memo = data.support_decline_setting_memo
        state.rejectReviewOrg.support_stop_flag = data.support_stop_flag
    },

    setMiddleTerminate(state, data) {
        state.middleTerminate.support_stop_flag = data.support_stop_flag
        state.middleTerminate.support_stop_setting_date = data.support_stop_setting_date
        state.middleTerminate.support_stop_reason = data.support_stop_reason
        state.middleTerminate.support_stop_memo = data.support_stop_memo
    },
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
