const USER_INFO = "USER_INFO";

export const getInfo = () => {
    return window.localStorage.getItem(USER_INFO);
};

export const saveInfo = (Info) => {
    window.localStorage.setItem(USER_INFO, Info);
};

export const destroyInfo = () => {
    window.localStorage.removeItem(USER_INFO);
};

export const getData = (key) => {
    return window.localStorage.getItem(key + getInfo());
};

export const saveData = (key, value) => {
    window.localStorage.setItem(key + getInfo(), value);
};

export const destroyData = (key) => {
    window.localStorage.removeItem(key + getInfo());
};

export default {
    getInfo,
    saveInfo,
    destroyInfo,
    getData,
    saveData,
    destroyData,
};
