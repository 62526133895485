import apiService from "./api.service";
import { DELETE, GET, PATCH, POST, PUT } from "../../constants";

export default {
    /**
   * Request
   * @param method
   * @param url
   * @param params
   * @param data
   * @param headers
   * @param config
   * @return {Promise<AxiosResponse<any>>}
   */
    async request(method, url, params, data, headers = {}, config = {}) {
        return apiService.request({
            ...config,
            url,
            params,
            data,
            method : method.toLowerCase(),
            headers,
        });
    },

    /**
   * Request GET
   * @param url
   * @param params
   * @param config
   * @return {Promise<AxiosResponse<*>>}
   */
    get(url, params= {}, config = {}) {
        return this.request(GET, url, params, {}, {}, config);
    },

    /**
   * Request POST
   * @param url
   * @param data
   * @param headers
   * @param config
   * @return {Promise<AxiosResponse<*>>}
   */
    post(url, data, headers = {}, config = {}) {
        return this.request(POST, url, {}, data, headers, config);
    },

    /**
   * Request PUT
   * @param url
   * @param data
   * @param config
   * @return {Promise<AxiosResponse<*>>}
   */
    put(url, data, config = {}) {
        return this.request(PUT, url, {}, data, {}, config);
    },

    /**
   * Request PUT
   * @param url
   * @param data
   * @param config
   * @return {Promise<AxiosResponse<*>>}
   */
    patch(url, data, config = {}) {
        return this.request(PATCH, url, {}, data, {}, config);
    },

    /**
   * Request DELETE
   * @param url
   * @param data
   * @param config
   * @return {Promise<AxiosResponse<*>>}
   */
    delete(url, data = {}, config = {}) {
        return this.request(DELETE, url, {}, data, {}, config);
    },
};
