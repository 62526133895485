import Vue from "vue";
import VueHead from "vue-head";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import i18n from "./plugins/i18n";
import vuetify from "./plugins/vuetify";
import "@/assets/scss/main.scss";
import "@/globalComponents.js";

Vue.config.productionTip = false;

import "jquery/src/jquery.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

import "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import Paginate from "vuejs-paginate";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ja";

Vue.use(datePicker);
Vue.use(VueHead);

Vue.component("paginate", Paginate);
import moment from "moment";

Vue.component("font-awesome-icon", FontAwesomeIcon)
Vue.use(moment);

Vue.use(VueToast, {
    position: "top-right",
    duration: 5000
});

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
