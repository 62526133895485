import AuthRepository from "@/services/api/AuthRepository";

import localStorage from "@/common/localStorage";
import { SUCCESS } from "../constants/";
import router from "../router";

export default (to, from, next, role = null) => {
    if (!localStorage.getInfo()) {
        return router.push({name: "login"});
    }
    AuthRepository.checkAuth()
        .then((res) => {
            if (res.status === SUCCESS) {
                window.localStorage.setItem("show_menu", true);
                if (role === null) {
                    next();
                    if (to.name === "SupportScheduleList") {
                        if (screen.width > 760) {
                            to.meta.layout = "default"
                        }
                        if (screen.width <= 760) {
                            to.meta.layout = "mobile"
                        }
                    }
                    return;
                }

                const handlePermissions = res.data.user.user_sys_auth_div;
                const dataUserRole = handlePermissions.split(",");
                var isRole = false;
                for (let i = 0; i < dataUserRole.length; i++) {
                    if (role.includes(parseInt(dataUserRole[i]))) {
                        isRole = true;
                        break;
                    }
                }

                if (isRole) {
                    next();
                    if (to.name === "SupportScheduleList") {
                        if (screen.width > 760) {
                            to.meta.layout = "default"
                        }
                        if (screen.width <= 760) {
                            to.meta.layout = "mobile"
                        }
                    }
                } else {
                    router.push({name: "dashboard"});
                }
            }else{
                window.localStorage.removeItem("show_menu");
                router.push({name: "login"});
            }
        }).catch(() => {
            window.localStorage.removeItem("show_menu");
            router.push({name: "login"});
        });
};
