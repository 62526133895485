import Repository from "./Repository";
import { AUTH_REPOSITORY } from "../../constants/";

export default {
    async login(data) {
        try {
            await Repository.get(`${AUTH_REPOSITORY}/csrf-cookie`, [])
            return await Repository.post(`${AUTH_REPOSITORY}/login`, data);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async logOut() {
        return await Repository.post(`${AUTH_REPOSITORY}/logout`, [])
            .catch((error) => {
                throw error;
            });
    },
    async sendMail(data) {
        try {
            await Repository.get(`${AUTH_REPOSITORY}/csrf-cookie`, [])
            return await Repository.post(`${AUTH_REPOSITORY}/send-mail-reset-password`, data);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async ConfirmCode(data) {
        try {
            return await Repository.post(`${AUTH_REPOSITORY}/check-code-reset-password`, data);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async ChangeResetPassword(data) {
        try {
            return await Repository.post(`${AUTH_REPOSITORY}/reset-password`, data);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async ConfirmLogin(data) {
        try {
            return await Repository.post(`${AUTH_REPOSITORY}/login-confirm`, data);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async ChangePassword(data) {
        try {
            return await Repository.post(`${AUTH_REPOSITORY}/change-password`, data);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async checkAuth() {
        try {
            return await Repository.post(`${AUTH_REPOSITORY}/check-auth`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    setErrors(e) {
        const errorCode = e.response ? e.response.status : 500;
        return {
            status : false,
            errorCode : errorCode,
            data : e.response ? e.response.data : "error",
            error : e,
        };
    },
    catchError(e) {
        return e.response ? e.response.data : "error";
    },
};
