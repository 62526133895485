const state = {
    receptionId: undefined,
    segmentBlockA: undefined,
    segmentBlockB: undefined,
    segment: undefined,
    segmentBlockL: undefined,
    reloadFormSupport: undefined,
    segmentCurrentPage: undefined,
    segmentCurrent: undefined,
    pagePrevious: undefined,
}

const getters = {
    getReceptionId(state) {
        return state.receptionId
    },
    getSegmentBlockA(state) {
        return state.segmentBlockA
    },
    getSegmentBlockB(state) {
        return state.segmentBlockB
    },
    getSegment(state) {
        return state.segment
    },
    getSegmentBlockL(state) {
        return state.segmentBlockL
    },
    getReloadFormSupport(state) {
        return state.reloadFormSupport
    },
    getSegmentCurrentPage(state) {
        return state.segmentCurrentPage
    },
    getSegmentCurrent(state) {
        return state.segmentCurrent
    },
    getPagePrevious(state) {
        return state.pagePrevious
    },
}

const mutations = {
    setReceptionId(state, receptionId) {
        state.receptionId = receptionId
    },
    setSegmentBlockA(state, data) {
        state.segmentBlockA = data
    },
    setSegmentBlockB(state, data) {
        state.segmentBlockB = data
    },
    setSegment(state, data) {
        state.segment = data
    },
    setSegmentBlockL(state, data) {
        state.segmentBlockL = data
    },
    setReloadFormSupport(state, data) {
        state.reloadFormSupport = data
    },
    setSegmentCurrentPage(state, data) {
        state.segmentCurrentPage = data
    },
    setSegmentCurrent(state, data) {
        state.segmentCurrent = data
    },
    setPagePrevious(state, data) {
        state.pagePrevious = data
    },
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};