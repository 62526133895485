import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
    mode : "history",
    base: "/",
    routes,
});

router.beforeEach((toRoute, fromRoute, next) => {
    window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : "Alumni";

    next();
})

router.beforeResolve((to, from, next) => {
    // if (to.name === "SupportScheduleListMobile") {
    //     if (screen.width > 760) {
    //         to.meta.layout = "default"
    //     }
    //     if (screen.width <= 760) {
    //         to.meta.layout = "MobileLayout"
    //     }
    // }

    if (to.name) {
        document.title = (to.meta.title || "Login");
    }
    next();
});

export default router;
