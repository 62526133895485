import axios from "axios";
import localStorage from "@/common/localStorage";
import { UNAUTHORIZED, INTERNAL_SERVER_ERROR } from "@/constants";
import { ENVS } from "@/common/constants";
import router from "../../router";

const apiService = () => {
    const defaultOptions = {
        baseURL : `${ENVS.API_URL_BASE}/api/`,
        headers : {
            "X-Requested-With" : "XMLHttpRequest",
        },
        withCredentials: true
    };

    // Create instance
    let instance = axios.create(defaultOptions);


    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config) {
        // detect IE11 or IE10
        if (
            navigator.userAgent.match(/Trident.*rv:11\./) ||
      navigator.userAgent.match(/MSIE/i)
        ) {
            config.headers.common["Cache-Control"] = "no-cache";
        }

        return config;
    });

    instance.interceptors.response.use(
        (response) => response,
        (error) => {
            const errorStatus = error.response.status;
            if (errorStatus === UNAUTHORIZED) {
                localStorage.destroyInfo();
            }
            throw error.response.data;
        }
    );

    return instance;
};

export default apiService();
