export const USER_REPOSITORY = "user";
export const AUTH_REPOSITORY = "";
export const ADMIN_REPOSITORY = "admin";
export const CODE_DEFINE_REPOSITORY = "code_define";
export const SYSTEM_USER_REPOSITORY = "users";
export const SYSTEM_USER_MODEL = "system_user";
export const SUPPORT_FLOW = "support-flow"
export const DASHBOARD = "dashboard";
export const GENERAL_NAME = "general-name";
export const CLIENT = "client"
export const LINE_REPOSITORY = "line";