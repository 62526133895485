const state = {
    isLoading: false,
    user: undefined,
    checkUpdateHealthGuidanceReceptions: null

}

const getters = {
    getIsLoading(state) {
        return state.isLoading
    },
    getUser(state) {
        return state.user
    },
    getCheckUpdateHealthGuidanceReceptions(state) {
        return state.checkUpdateHealthGuidanceReceptions
    }
}

const mutations = {
    setIsLoading(state, isLoading) {
        state.isLoading = isLoading
    },
    setUser(state, user) {
        state.user = user
    },
    setCheckUpdateHealthGuidanceReceptions(state, data) {
        state.checkUpdateHealthGuidanceReceptions = data
    },
    showLoading(state) {
        state.isLoading = true
    },
    hideLoading() {
        state.isLoading = false
    }
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};